import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './services/auth.service';
import { UtilitiesService } from './services/utilities.service';
import { AdminService } from './services/admin.service';

/* -------------------------------------------------------------------------- */
/*                                 AngularFire                                */
/* -------------------------------------------------------------------------- */
import {
  AngularFireModule
} from '@angular/fire';
import {
  AngularFirestoreModule
} from '@angular/fire/firestore';
import {
  credentials
} from './credentials';
import {
  AngularFireAuthGuardModule
} from '@angular/fire/auth-guard';
import {
  AngularFireAuthModule
} from '@angular/fire/auth';
import {
  AngularFireStorageModule
} from '@angular/fire/storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import {
  HttpClientModule
} from '@angular/common/http';
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
     /* ------------------------------ Angular fire ------------------------------ */
     AngularFireModule.initializeApp(credentials.firebaseConfig),
     AngularFireAuthGuardModule,
     AngularFireAuthModule,
     AngularFirestoreModule,
     AngularFireStorageModule,
     NgxSpinnerModule,
     FormsModule,
     ReactiveFormsModule,
     Ng2SearchPipeModule,
     HttpClientModule
  ],
  providers: [
    AdminService,
    AuthService,
    UtilitiesService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
