import {
  Injectable
} from '@angular/core';
import {
  AngularFireAuth
} from '@angular/fire/auth';
import {
  AngularFirestore
} from '@angular/fire/firestore';
import {
  first
} from 'rxjs/operators';

import {
  auth
} from 'firebase/app';
import * as firebase from 'firebase/app';

import {
  Router
} from '@angular/router';
import {
  UtilitiesService
} from './utilities.service';
import {
  AuthService
} from './auth.service';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import {
  map
} from 'rxjs/operators';
// import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  public listingListRef: firebase.firestore.DocumentReference;
  public listing;
  public user;
  aggregatorCookie: any = 'ajs_anonymous_id=%22br-892544e8-c18a-4b28-8b62-a5187e6165e0%22; __stripe_mid=8e4e8832-afe2-4569-aad2-b9c7c9f789837a27d2; g_state={"i_l":0}; auth=eyJfcmFpbHMiOnsibWVzc2FnZSI6IkltVjVTbWhpUjJOcFQybEtTVlY2VlhoTmFVbzVMbVY1U210WldGSm9TV3B3TjBsdVRtcGlNMEpzU1dwd1lrbHRSbmRoVTBwa1RFTktNV015Vm5sSmFuQTNTVzFzYTBscWIybE9ha1V6VDBSVk5WcFhWVEZPZWtsNlRtcEpkMDFFUVRCYWFtZDNUMVJPYUVsdU1UbE1RMHBzWlVoQmFVOXFSVEpPUkVFd1RtcEZlVTlVV1hOSmJXeG9aRU5KTmsxVVdYcE9WRWt6VG5wSk5VNXVNQzVwWXkwM1N6aFZaMUJZVlhFMVFtczBkekZoVFUwMWNHUmZVbkpMTTFGTWFsbElNaTFNVDJ3MmJIWlpTRGw0VlhoTlMwWlhVRzh5VmtGUVpUbGhhRFZsYTBvdFJUTTVTblpFYUhFMVRuQXpZa0pPTTFwR1FTST0iLCJleHAiOm51bGwsInB1ciI6ImNvb2tpZS5hdXRoIn19--1ef85abfc1365c5c7db7a357069a3b940c8f2ad6; qualifications=null; accredited=false; last_search_query=%7B%22accredited%22%3A%22false%22%2C%22sort%22%3A%7B%22field%22%3A%22trending_metric%22%2C%22direction%22%3A%22-1%22%7D%2C%22trading%22%3A%22true%22%2C%22ipo%22%3A%22true%22%2C%22page%22%3A%221%22%2C%22controller%22%3A%22api%2Fv1%2Foffers%22%2C%22action%22%3A%22index%22%2C%22offer%22%3A%7B%7D%2C%22ts%22%3A%222021-11-10T22%3A40%3A52.060%2B00%3A00%22%7D; __stripe_sid=160774da-68b0-40fd-88a4-42ebf9086a194e8ee3; TawkConnectionTime=0; _csrf=eyJfcmFpbHMiOnsibWVzc2FnZSI6IklqUTVaV0kzTVRNeFlqZ3paVFJsTUdRd1pXWXdNV05sTm1ObE5qQXpOakE1WkRObU1UbGhNVFl4TXpnMFlXSmxNVFpoT0RZeVlXWXpOV05tTnpJek1tWXVNVFl6TmpVNE5EUXhNeUk9IiwiZXhwIjpudWxsLCJwdXIiOiJjb29raWUuX2NzcmYifX0%3D--5e2fdb4a1af790a99d04320200e4eda292bde815; csrf=49eb7131b83e4e0d0ef01ce6ce603609d3f19a161384abe16a862af35cf7232f.1636584413'
  csrfToken: any = '49eb7131b83e4e0d0ef01ce6ce603609d3f19a161384abe16a862af35cf7232f.1636584413';
  constructor(
    private afAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    private router: Router,
    private utils: UtilitiesService,
    private authService: AuthService,
    private http: HttpClient
  ) {}


  // async updateListing(listingId: string, listingData: any): Promise < any > {
  //   return firebase.firestore().doc(`listings/${listingId}`).set(listingData, {
  //     merge: true
  //   });
  // }

  getDeals(page) {
    return this.http.get(`https://deals-api-dot-moneymade-stage.uc.r.appspot.com/deal?page=${page}`, {
      observe: 'response',
      params: { }
    }).toPromise();
  }

  getManyDeals() {
    return this.http.post(`https://api.honeycoin.app//v1/fin-pro-aggregator`, {
      cookie: this.aggregatorCookie,
      token: this.csrfToken
    }, {
      observe: 'response',
      params: { }
    }).toPromise();
  }

  // #1
  getVintDeals() {
    return this.http.get(`https://api.vint.co/collections`, {
      observe: 'response',
      params: { }
    }).toPromise();
  }

  async addListing(data: any) {
    const id = this.firestore.createId();
    data.listingId = id;
    firebase.firestore().doc(`listings/${id}`).set(data);
  }

  async addIMData(data: any) {
    const id = this.firestore.createId();
    data.matchId = id;
    firebase.firestore().doc(`investment_match_data/${id}`).set(data);
  }

  async updateListing(data: any) {
    return firebase.firestore().doc(`listings/${data.listingId}`).update(data);
  }

  async deleteListing(listingId) {
    return firebase.firestore().doc(`listings/${listingId}`).delete();
  }

  async getListing(id) {
    return firebase.firestore().collection(`listings`).doc(id).get();
  }

  async getListings(): Promise < any > {
    return firebase.firestore().collection('listings').orderBy('ranking', 'asc').get();
  }

  addRatingToCompany(ratings, id) {
    return firebase.firestore().doc(`listings/${id}`).update({
      ratings: ratings
    })
  }

  async getThirdParties(type): Promise < any > {
    return firebase.firestore().collection('third_parties').orderBy('dateCreated', 'desc').where('type', '==', type).get();
  }


  async addThirdPartySite(data: any) {
    const id = this.firestore.createId();
    data.widgetId = id;
    return firebase.firestore().doc(`third_parties/${id}`).set(data);
  }

  async removeThirdPartySite(id) {
    return firebase.firestore().doc(`third_parties/${id}`).delete();
  }

  async updateSiteViews(id, ip) {
    const listingRef = firebase.firestore().collection('third_parties').doc(id);
    try {
      const res = await firebase.firestore().runTransaction(async t => {
        const doc = await t.get(listingRef);
        const listingViews = doc.data().uniqueViews;
        let newViews = doc.data().views + 1;
        listingViews.push(ip);
        let unique = [...new Set(listingViews)];
        await t.update(listingRef, {
          uniqueViews: unique,
          views: newViews
        });
        return `Views increased to ${unique.length}`;
      });
    } catch (e) {
      console.log('Failure updating L114 A_SERVICE:', e);
    }
  }
  

  sendEmail(data) {
    return this.http.get(`https://us-central1-honeycoin-app.cloudfunctions.net/main/send-custom-email`, {
      observe: 'response',
      params: {
        "senderName": "Financial Professional",
        "senderEmail": "mp.financialprofessional@gmail.com",
        "customerEmail": data.email,
        "emailSubject": data.subject,
        "link": data.link,
        "audienceId": data.audienceId,
        "customerFirstName": data.firstName,
        "customerLastName": data.lastName
      }
    }).toPromise();
  }

  getIp() {
    return this.http.get(('https://ipapi.co/json/'), {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    }).pipe(map(results => results));
  }

  jsonToCSV(data, columnDelimiter = ",", lineDelimiter = "\n") {

    let result, ctr, keys, realKeys;

    if (data === null || !data.length) {
      return null
    }

    keys = Object.values(data[0])
    realKeys = Object.keys(data[0]);

    result = ""
    result += keys.join(columnDelimiter)
    result += lineDelimiter
    let actualArray = data.shift();
    data.forEach(item => {
      ctr = 0
      realKeys.forEach(key => {
        if (ctr > 0) {
          result += columnDelimiter
        }

        result += typeof item[key] === "string" && item[key].includes(columnDelimiter) ? `"${item[key]}"` : item[key]
        ctr++
      })
      result += lineDelimiter
    })

    return result
  }

  downloadCSV(fileName, urlData) {
    var aLink = document.createElement('a');
    var evt = document.createEvent("HTMLEvents");
    evt.initEvent("click");
    aLink.download = fileName;
    aLink.href = urlData;
    aLink.dispatchEvent(evt);
  }

  downloadFile(csv, fileName) {
    var pom = document.createElement('a');
    var csvContent = csv; //here we load our csv data 
    var blob = new Blob([csvContent], {
      type: 'text/csv;charset=utf-8;'
    });
    var url = URL.createObjectURL(blob);
    pom.href = url;
    pom.setAttribute('download', `${fileName}.csv`);
    pom.click();
  }

  
  async getALLIMData(): Promise < any > {
    return firebase.firestore().collection('investment_match_data').get();
  }


}