export const credentials = {
  firebaseConfig: {
    apiKey: "AIzaSyD36lNQBOyIXU9b2XbWbLDsjIgjJbpsIxk",
    authDomain: "directory-app-ff16f.firebaseapp.com",
    databaseURL: "https://directory-app-ff16f.firebaseio.com",
    projectId: "directory-app-ff16f",
    storageBucket: "directory-app-ff16f.appspot.com",
    messagingSenderId: "725312257351",
    appId: "1:725312257351:web:a8d5a1779b838ee192a062",
    measurementId: "G-Z271543Q96"
  }
}