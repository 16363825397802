import {
  NgModule
} from '@angular/core';
import {
  Routes,
  RouterModule
} from '@angular/router';

const routes: Routes = [{
    path: '',
    loadChildren: () => import('./pages/directory/directory.module').then(m => m.DirectoryModule)
  },
  {
    path: 'iv/:asset',
    loadChildren: () => import('./pages/directory/directory.module').then(m => m.DirectoryModule)
  },
  {
    path: 'directory',
    loadChildren: () => import('./pages/directory/directory.module').then(m => m.DirectoryModule)
  },
  {
    path: 'admin/add-listing',
    loadChildren: () => import('./pages/add-listing/add-listing.module').then(m => m.AddListingModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'admin/dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignupModule)
  },
  {
    path: 'admin/edit-listing/:id',
    loadChildren: () => import('./pages/edit-listing/edit-listing.module').then(m => m.EditListingModule)
  },
  {
    path: 'investment-match',
    loadChildren: () => import('./pages/investment-match/investment-match.module').then(m => m.InvestmentMatchModule)
  },
  {
    path: 'results',
    loadChildren: () => import('./pages/results/results.module').then(m => m.ResultsModule)
  },
  {
    path: 'admin/view-third-parties',
    loadChildren: () => import('./pages/view-sites/view-sites.module').then(m => m.ViewSitesModule)
  },
  {
    path: 'widget/:id',
    loadChildren: () => import('./widget/widget.module').then(m => m.WidgetModule)
  },
  {
    path: 'widget',
    loadChildren: () => import('./widget/widget.module').then(m => m.WidgetModule)
  },
  {
    path: 'inv-calc',
    loadChildren: () => import('./pages/investment-calculator/investment-calculator.module').then(m => m.InvestmentCalculatorModule)
  },
  {
    path: 'admin/view-cic-widgets',
    loadChildren: () => import('./view-calcs/view-calcs.module').then(m => m.ViewCalcsModule)
  },
  {
    path: 'l/:id',
    loadChildren: () => import('./single-listing/single-listing.module').then(m => m.SingleListingModule)
  },
  {
    path: 'ag',
    loadChildren: () => import('./pages/aggregator/aggregator.module').then(m => m.AggregatorModule)
  },
    { path: 'aggregator', loadChildren: () => import('./pages/aggregator/aggregator.module').then(m => m.AggregatorModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}