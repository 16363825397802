import { credentials } from './credentials';
import {
  Component,
  OnInit
} from '@angular/core';
declare var $;
import * as firebase from 'firebase/app';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor() {
    firebase.initializeApp(credentials.firebaseConfig);
  }

  ngOnInit() {
    let elem: any = document.querySelector('.loading-text')
    if (elem) elem.style.display = 'none';
  }


}